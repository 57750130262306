import { MDCTopAppBar } from '@material/top-app-bar'
import { MDCMenu, Corner } from '@material/menu'
import { MDCList } from '@material/list'
import { MDCRipple } from '@material/ripple'
import { DefaultFocusState } from '@material/menu/constants'
import { MDCTextField } from '@material/textfield'
import { MDCDialog } from '@material/dialog'
import { MDCCircularProgress } from '@material/circular-progress'

window.mdc = {}

// PROGRESS
document.querySelectorAll('.mdc-circular-progress').forEach(function (progCirc) {
  // eslint-disable-next-line no-unused-vars
  const circularProgress = new MDCCircularProgress(progCirc)
})

// TOPBAR
const topAppBarElement = document.querySelector('.mdc-top-app-bar')
if (topAppBarElement) {
  // eslint-disable-next-line no-unused-vars
  const topAppBar = new MDCTopAppBar(topAppBarElement)
}

window.mdc.MDCMenu = MDCMenu

// DIALOG
window.mdc.MDCDialog = MDCDialog

// LISTS
if (document.querySelector('.mdc-list:not(.mdc-drawer)')) {
  // eslint-disable-next-line no-unused-vars
  const list = new MDCList(document.querySelector('.mdc-list'))
}

// COLLAPSABLE CONTENT
document.querySelectorAll('.collapsible').forEach(function (current) {
  const toggler = document.createElement('div')
  toggler.className = 'toggler'
  current.appendChild(toggler)

  toggler.addEventListener('click', function (e) {
    current.classList.toggle('open')
  }, false)
})

// BUTTONS
const iconButtonRipple = new MDCRipple(document.querySelector('.mdc-icon-button'))
iconButtonRipple.unbounded = true

const buttons = document.querySelector('.mdc-button')
if (buttons) {
  // eslint-disable-next-line no-unused-vars
  const buttonRipple = new MDCRipple(document.querySelector('.mdc-button'))
}

// TEXT FIELDS
const textFields = document.querySelector('.mdc-text-field')
if (textFields) {
// eslint-disable-next-line no-unused-vars
  const textField = new MDCTextField(document.querySelector('.mdc-text-field'))
}

// TOP SETTINGS MENU
const settingsElem = document.querySelector('#settingsMenu')
if (settingsElem) {
  const topMenu = new MDCMenu(settingsElem)
  topMenu.setAnchorCorner(Corner.TOP_RIGHT)
  window.menuClick = () => {
    topMenu.open = true
  }
  topMenu.listen('MDCMenu:selected', e => {
    // use menu text instead of index to allow for different menu items depending on user permission level
    const item = e.detail.item
    const itemText = item?.querySelector('.mdc-deprecated-list-item__text').textContent
    const uni = item.dataset.university || false
    const appView = item.dataset.app || false
    if (itemText === 'Settings') {
      window.location = '/settings'
    } else if (itemText === 'Admin') {
      if (uni) {
        window.location = `/admin/university/${uni}`
      } else {
        window.location = '/admin/universities'
      }
    } else if (itemText === 'Users') {
      window.location = '/admin/users'
    } else if (itemText === 'My Events') {
      if (uni) {
        window.location = `/a2z/${uni}/events?own=true`
      } else {
        window.location = '/my-a2z'
      }
    } else if (itemText === 'My Jobs') {
      if (uni) {
        window.location = `/a2z/${uni}/jobs?own=true`
      } else {
        window.location = '/my-a2z'
      }
    } else if (itemText === 'My Liked') {
      window.location = '/liked'
    } else if (itemText === 'My Reviews') {
      if (uni) {
        window.location = `/a2z/${uni}/reviews?own=true`
      } else {
        window.location = '/my-a2z'
      }
    } else if (itemText === 'My Societies') {
      if (uni) {
        window.location = `/a2z/${uni}/societies?own=true`
      } else {
        window.location = '/my-a2z'
      }
    } else if (itemText === 'My Tips') {
      if (uni) {
        window.location = `/a2z/${uni}/tips?own=true`
      } else {
        window.location = '/my-a2z'
      }
    } else {
      signout(appView)
    }
  })
}

// SEARCH
const searchElem = document.querySelector('#searchMenu')
if (searchElem) {
  const searchMenu = new MDCMenu(searchElem)
  searchMenu.setDefaultFocusState(DefaultFocusState.NONE)
  searchMenu.setAnchorElement(document.querySelector('#searchbox'))
  window.searchOpen = function () {
    searchMenu.open = true
  }
  window.searchClose = function () {
    searchMenu.open = false
  }
  searchMenu.listen('MDCMenu:selected', e => {
    if (window.searchCallback) {
      window.searchCallback(e)
    }
  })
}

const { firebaseConfig } = require('./firebaseConfig')

// eslint-disable-next-line no-undef
firebase.initializeApp(firebaseConfig)

const initApp = function () {
  // eslint-disable-next-line no-undef
  firebase.analytics() // call to activate

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const type = urlParams.get('t')
  if (type) {
    // eslint-disable-next-line no-undef
    firebase.analytics().logEvent(type)
  }

  // eslint-disable-next-line no-undef
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      user.getIdToken().then(function (accessToken) {
        document.getElementById('sign-in-status').textContent = 'Signed in'
        document.getElementById('account-details').textContent = JSON.stringify({
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          phoneNumber: user.phoneNumber,
          photoURL: user.photoURL,
          uid: user.uid,
          accessToken,
          providerData: user.providerData
        }, null, '  ')
      })
    } else {
      document.getElementById('sign-in-status').textContent = 'Signed out'
      document.getElementById('account-details').textContent = 'null'
    }
  }, function (error) {
    console.error('base.firebase.auth.onAuthStateChanged', error)
  })
}

const signout = async (appView) => {
  // console.log('signout', appView)
  const response = await fetch('/auth/sessionLogout', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: ''
  })
  response.json().then(data => {
    if (data.status === 'success') {
      // eslint-disable-next-line no-undef
      firebase.auth().signOut()
      if (appView) {
        window.location = '/app/signin'
      } else {
        window.location = '/'
      }
    }
  })
}

const initButtons = () => {
  const signoutBtn = document.getElementById('signout-btn')
  const signinBtn = document.getElementById('signin-btn')
  if (signinBtn) {
    // console.log(signinBtn, signinBtn.dataset)
    if (signinBtn.dataset.app) {
      signinBtn.onclick = () => { window.location = '/app/signin' }
    } else {
      signinBtn.onclick = () => { window.location = '/signin' }
    }
  }
  if (signoutBtn) {
    signoutBtn.onclick = signout
  }
}

window.addEventListener('load', function () {
  initApp()
  initButtons()
})

// Load site settings from localstorage if available
let settings = {}
const settingsJson = window.localStorage.getItem('studenta2z-settings')
if (settingsJson) {
  settings = JSON.parse(settingsJson)
}
const logoType = settings.logoType
const barHeight = settings.barHeight
const body = document.getElementById('body')
if (logoType) {
  body.classList.add('logo-' + logoType)
}
if (barHeight) {
  body.classList.add('topbar-' + barHeight)
}
